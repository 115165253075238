import SnackBarModel from "@/models/snackBar/snackBarModel";
import { getRoleListService } from "@/services/clubDetails/setting.service";
import { getMenuWithPermissionService } from "@/services/clubDetails/common.service";

export type State = {
    isLoading:boolean
};
const state: State = {
    isLoading:false
};

const metrics = {
  state,
  getters: {},
  mutations: {
    
    SET_LOADER_METRICS(state: { isLoading: string }, data: any) {
      state.isLoading = data;
    },
  },
  actions: {
  }
};
export default metrics;
